import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { ApiService } from 'src/app/common/interceptor/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/interceptor/message/messagehandling.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss']
})
export class EditPhoneComponent {
  phoneForm: FormGroup
  selectedSocialInfo: any;
  selectedMemberCount: any;
  imageBaseUrl: any
  profileIconArrayLength: any
  userData: any;
  userInfo: any;
  highlyttoggle: boolean = false;
  profileData: any = []
  selectedProfileData: any = [];
  selectedProfileCount: any = 0;
  selectAllCheckbox: boolean = false;
  profileImageUrl: any;
  searchText: any
  phoneUtil = PhoneNumberUtil.getInstance();
  isValid: boolean = false;
  formattedNumber: any;
  telInputOptions = {
    initialCountry: 'fr',
    autoHideDialCode: false,
    nationalMode: false
  };
  countryCodeSelected: string = 'fr';
  inputIsChanged: boolean = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<EditPhoneComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private fb: FormBuilder, private router: Router, private api: ApiService, public message: MessagehandlingService) {
    this.phoneForm = this.fb.group({
      highlight: data.block.highlight == '' || data.block.highlight == '0' ? false : true,
      title: data.block.name,
      description: data.block.description,
      active: '',
      content: data.block.content,
      country_code: data.block.country_code.toLowerCase(),
      dial_code: data.block.dial_code.replace('+', ''),
      type: '',
      slug: '',

    })
    this.countryCodeSelected = data.block.country_code.toLowerCase();
  }

  ngOnInit(): void {
    this.getMemberData()
    this.profileImageUrl = environment.userImage
    this.selectedSocialInfo = this.data.block;
    this.selectedMemberCount = Number(sessionStorage.getItem('SelectedMembersCount'));
    this.imageBaseUrl = this.api.baseSocialImageUrl
    this.userData = (sessionStorage.getItem('userinformation'))
    this.userInfo = JSON.parse(this.userData)
  }

  // getProfileList() {
  //   let formData = new FormData();
  //   formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
  //   this.api.profileList(formData).subscribe((response: any) => {
  //     if (response.success == 1) {
  //       this.profileData = response.items
  //     }
  //   })
  // }

  getMemberData() {
    let form = new FormData
    this.api.userTeamList(form).subscribe((res: any) => {
      res.items.forEach((element: any) => {
        if (element.member_status != 4) {
          this.profileData.push(element)
        }
      });
    })
  }

  stopClose(e: any) {
    e.stopPropagation();
  }

  closeFilter() {
    if (this.selectedProfileData != '') {
      let fd = new FormData
      // fd.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      fd.append('user_id', this.selectedProfileData)
      fd.append('type', 'primary')
      fd.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
      fd.append('content', this.phoneForm.value.content)
      fd.append('title', this.phoneForm.value.title)
      fd.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
      fd.append('content', this.phoneForm.value.content)
      fd.append('country_code',  this.phoneForm.value.country_code.toUpperCase())
      fd.append('dial_code', '+' + this.phoneForm.value.dial_code)
      fd.append('slug', this.selectedSocialInfo?.slug)
      fd.append('active', '')
      fd.append('type', 'primary')
      this.api.saveLinksForMultipleProfile(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.msg, 1000)
        $('.filter_dropdown .dropdown-menu, .exportesv .dropdown-menu').removeClass('show');
        this.dialogRef.close()
      })
    }
  }


  checkMat(event: any, index: number, data: any) {
    this.profileData[index].checked = event.checked;
    if (event.checked == true) {
      this.selectedProfileCount = this.selectedProfileCount + 1;
      this.selectedProfileData.push(data.user_id)
      if (this.selectedProfileCount == this.profileData.length) {
        this.selectAllCheckbox = true;
      }
    }
    else {
      this.selectedProfileCount = this.selectedProfileCount - 1;
      this.selectAllCheckbox = false;

      this.selectedProfileData.forEach((element: any, index: number) => {
        if (element == data.user_id) {
          this.selectedProfileData.splice(index, 1)
        }
      })
    }
  }

  selectAllProfile(event: any) {
    this.selectedProfileData = []
    if (event.checked == true) {
      this.profileData.forEach((res: any, index: number) => {
        this.selectedProfileData.push(res.user_id)
        this.profileData[index].checked = true;
        this.selectedProfileCount = this.profileData.length;
      });

    }
    else {
      this.profileData.forEach((res: any, index: number) => {
        this.profileData[index].checked = false;
        this.selectAllCheckbox = false;
        if (this.selectedProfileCount == this.profileData.length) {
          this.selectedProfileData = []
        }
        this.selectedProfileCount = 0;
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backToAddContent(): void {
    this.dialogRef.close();
  }

  highlightbtn(event: any) {
    this.highlyttoggle = event.checked;
  }
  deleteIcon(id: any, slug: any) {
    let formData = new FormData()
    formData.append('id', id)
    formData.append('slug', slug)
    this.api.deleteLink(formData).subscribe((res: any) => {
      this.message.sucessMessage(`${this.selectedSocialInfo?.slug} deleted successfully`, 2000)
      this.dialog.closeAll();
    })
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  onCountryChange(data: any) {
    debugger
    this.phoneForm.patchValue({
      country_code: data.iso2,
      dial_code: data.dialCode
    });
    this.countryCodeSelected = data.iso2;
    this.formatPhoneNumber();
  }
  
  get content() {
    console.log(this.phoneForm.get('dial_code')!.value + '' + this.phoneForm.get('content')!.value);

    return '+' + this.phoneForm.get('dial_code')!.value + '' + this.phoneForm.get('content')!.value
  }


  previewLink() {
    let contentWithoutPlus = this.content.replace('+', '').replace(/\s+/g, '');
    window.open(this.selectedSocialInfo?.base_url + contentWithoutPlus);
  }

  submitForm() {
    if (
       !this.phoneForm.get('content')?.value
      // !this.phoneForm.get('title')?.value
      // !this.phoneForm.get('country_code')?.value ||
      // !this.phoneForm.get('dial_code')?.value
    ) {
      this.message.errorMessage('Please fill in all required fields.', 2000);
      return;
    } else {
      let formData = new FormData();
      formData.append('id', this.selectedSocialInfo.id)
      formData.append('highlight', this.phoneForm.value.highlight == '' || this.phoneForm.value.highlight == false ? '0' : '1')
      formData.append('content', this.phoneForm.value.content)
      formData.append('title', this.phoneForm.value.title)
      formData.append('description', this.phoneForm.value.highlight == true ? this.phoneForm.value.description : '')
      formData.append('country_code',  this.phoneForm.value.country_code.toUpperCase())
      formData.append('dial_code', '+' + this.phoneForm.value.dial_code)
      formData.append('slug', this.selectedSocialInfo?.slug)
      formData.append('active', '')
      // formData.append('content', this.phoneForm.value.content)
      formData.append('type', 'primary')
      formData.append('user_id', sessionStorage.getItem('SelectedMembersId') || '')
      formData.append('profile_id', sessionStorage.getItem('SelectedContactId') || '')
      this.api.updateLink(formData).subscribe((res: any) => {
        this.message.sucessMessage(`${this.selectedSocialInfo?.slug} successfully updated to card`, 2000)
        this.dialog.closeAll();
      })
      const currentRoute = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
    }
  }

  telInputObject(obj: any) {
    obj.setCountry(this.phoneForm.value.country_code);
    obj.setNumber('+' + this.phoneForm.value.dial_code + this.phoneForm.value.content);
    //this.formatPhoneNumber();


  }


  onInputChange(event: any) {
    const inputValue: string = event.target.value;
    console.log('Input value:', inputValue);

    this.phoneForm.get('country_code')?.setValue(this.countryCodeSelected);
  }

  formatPhoneNumber() {
    if (this.phoneForm.value.content && this.phoneForm.value.country_code && typeof this.phoneForm.value.content === 'string' && typeof this.phoneForm.value.country_code === 'string' && this.phoneForm.value.content?.length > 5) {
      const parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.phoneForm.value.content, this.phoneForm.value.country_code);
      this.isValid = this.phoneUtil.isValidNumber(parsedNumber);
      this.formattedNumber = this.phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL);
      this.phoneForm.get('content')?.setValue(this.formattedNumber);



    } else {
      console.error('Invalid input types');
      this.isValid = false;
    }
  }



  getCountryCode(dialCode: string) {
    try {
      const regionCode = this.phoneUtil.getRegionCodeForCountryCode(parseInt(dialCode, 10));
      const countryCode = regionCode;
      return countryCode;
    } catch (error) {
      console.error('Error getting country code:', error);
      return 'fr';
    }
  }

}
